.Container {
  width: 100%;
  display: grid;
  row-gap: 40px;
}
.paginationContainer {
  display: flex;
  justify-content: center; 
  align-items: center;      
  margin-top: 20px;          
}
.GroupPurchaseOfProducts_renderedContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(290px, 317px);
  justify-content: space-between;
  gap: 60px;
}

.ProductsPatents {
  display: grid;
  grid-template-columns:
    minmax(170px, 1fr) 56.66px
    minmax(170px, 1fr) 56.66px
    minmax(170px, 1fr);
  align-items: stretch;
  justify-content: space-between;
  gap: 41px;
}

.GroupProductItem {
  border: 0.65px solid #93939380;
  padding: 13.29px;
  border-radius: 9.74px;
}

.GroupInfoBlock {
  align-self: center;
  margin-top: 20px;
}

.GroupPriceAndSale {
  display: flex;
  align-items: center;
  gap: 20.79px;
}

.GroupProductPlusIcon {
  align-self: center;
  min-width: 14px;
  min-height: 14px;
  max-width: 56.66px;
  max-height: 56.66px;
  aspect-ratio: 1/1;
}

.GroupSaleParent {
  width: 49px;
  height: 49px;
  display: grid;
  place-items: center;
  position: relative;
}
.GroupSalePercent {
  font-size: 14.83px;
  font-style: italic;
  font-weight: 900;
  text-align: left;
  color: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.GroupPrice {
  font-size: 27px;
  font-weight: 900;
  line-height: 46.88px;
  text-align: left;
  color: var(--red);
}
.GroupInfoCash{
  border: 1px solid #bababa;
  border-radius: 19px;
  padding: 16px 19px;
}
.GroupCreditContainer {
  gap: 20px;
  
  margin-top: 7px;
  display: flex; 
  align-items: center;
  
}

.GroupCreditTitle {
  font-size: 12.09px;
  font-weight: 700;
  line-height: 14.17px;
  text-align: left;
  color: #282828b2;
  text-transform: uppercase;
}

.GroupCreditPrice {
  font-size: 16.12px;
  font-weight: 700;
  line-height: 18.9px;
  text-align: left;
  color: #282828;
}

.GroupBuyContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30.43px;
}

.GroupButtonBuyNow {
  border: none;
  width: fit-content;
  padding: 11.31px 13.37px;
  gap: 10.29px;
  border-radius: 10px;
  background: var(--red);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--red);
  font-size: 16px;
  font-weight: 500;

  &:hover {
    background: var(--dark-red);
  }
}

.GroupButtonBuyOnCredit {
  border: none;
  width: fit-content;
  background: transparent;
  color: #282828;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding: 11.31px 13.37px;
  border: 0.76px solid #cdcdcd;
  border-radius: 10px;

  &:hover {
    background: var(--dark-red);
    color: #ffffff;
  }
}

.InCardSlider {
  width: 100%;
  margin: 0 auto;
  height: fit-content;
  position: relative;
  z-index: 1;
}

.stickers {
  position: absolute;
  top: 13px;
  left: 13px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 2;
}

.stickers__item {
  width: 50px;
}

.guarantee {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.guarantee span {
  display: flex;
  align-items: center;
  font-size: 10px;
  width: 100%;
  color: var(--white);
  background: linear-gradient(180.24deg, #e31335 0.21%, #c10322 48.39%);
  border-radius: 4px 4px 0 0;
  justify-content: center;
  font-size: 8px;
  padding: 4px;
  text-transform: uppercase;
  text-align: center;
}

.guarantee span:last-child {
  border-radius: 0 0 4px 4px;
  font-size: 9px;
  font-weight: bold;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  color: var(--black);
  background: var(--white) !important;
}

.giftBox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  min-width: 30px;
  width: 30px;
  height: 30px;
  min-height: 30px;
  justify-content: center;
  background: radial-gradient(
    96.77% 96.77% at 93.33% 96.77%,
    #af001d 0.51%,
    #e31335 100%
  );
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.giftBox:hover .giftHovered {
  display: flex;
}

.giftHovered {
  display: none;
  padding: 15px;
  top: 30px;
  right: -5px;
  gap: 5px;
  text-align: start;
  border-radius: 10px;
  position: absolute;
  max-width: 244px;
  width: max-content;
  font-family: 'Mardoto-Regular';
  background-color: var(--white);
  box-shadow: 0px 4.82129px 48.2129px rgb(0 0 0 / 12%);
}

.giftHoveredImg {
  height: 40px;
  max-width: 60px;
  object-fit: contain;
}

.GroupSliderImages {
  height: 135px;
  object-fit: contain;
  padding-bottom: 7px;
  width: 100%;
}

/* imported by ProductCard */

/* .product__wrapper {
  height: 204px;
} */

.product__wrapper > .swiper-pagination {
  position: absolute;
  display: block !important;
}

.brandImg {
  object-fit: contain;
  max-height: 20px;
  max-width: 60px;
  width: 100%;
  object-position: left;
  margin-bottom: 7px;
}

/* .descContainer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
} */

.descContainer a:hover .productName {
  text-decoration: underline;
}
.categoryName {
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-family: 'Mardoto-Bold';
  width: 100%;
  font-size: 10px;
  margin-bottom: 5.2px;
  word-break: break-word;
}

.productName {
  width: 100%;
  max-width: 200px;
  min-height: 32px;
  font-size: 13px;
  overflow: hidden;
  line-height: 16px;
}

.price {
  font-size: 17px;
  font-weight: bold;
}
.oldPriceTwo{
  font-size: 19px;
  font-weight: bold;
  color: #898383;
}
.newPrice {
  font-size: 16px;
  font-weight: bold;
  color: var(--red);
}
.oldPriceContainer,.oldPriceContainerTwo {
  position: relative;
}
.oldPrice {
  font-size: 12px;
  color: #898383;
}
.oldPriceContainerTwo .line {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--red);
  transform: rotate(-6deg);
  left: 0;
  top: 13px;
}
.oldPriceContainer .line {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--red);
  transform: rotate(-6deg);
  left: 0;
  top: 6px;
}
.priceMounth {
  width: max-content;
  font-size: 12px;
  color: var(--gray-bold);
  margin-top: 10px;
}

.cardBtnSvg {
  width: 21.13px;
  height: 21.13px;
}
.cardBtnSvg path {
  fill: #fff;
}

.cashbackContainer {
  margin-top: 10px;
  height: 12px;
}
.cashbackText {
  font-size: 14px;
  font-weight: bold;
}
.cashbackPrice {
  color: var(--red);
  font-size: 16px;
  font-weight: bold;
}

.promoPriceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
  width: calc(100% - 33px);
}

@media only screen and (max-width: 1350px) {
  .ProductsPatents {
    min-width: 0;
    max-width: 100%;
    gap: 15px;
    grid-template-columns:
      minmax(100px, 1fr)
      minmax(27.673px, 27.673px)
      minmax(100px, 1fr)
      minmax(27.673px, 27.673px)
      minmax(100px, 1fr);
  }
}

@media only screen and (max-width: 1200px) {
  .GroupPurchaseOfProducts_renderedContainer {
    gap: 30px;
  }
}

@media only screen and (max-width: 1100px) {
  .GroupPurchaseOfProducts_renderedContainer {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 0;
  }

  .GroupInfoBlock {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 180px;
    grid-template-rows: auto auto;
    gap: 10px;
  }

  .GroupButtonBuyNow {
    justify-self: flex-end;
    width: 100%;
  }

  .GroupCreditContainer {
    max-width: calc(100% - 300px);
    grid-area: 1 / 1 / 2 / 2;
    border: none;
    max-width: max-content;
  }

  .GroupPriceAndSale {
    grid-area: 2 / 1 / 3 / 2;
  }

  .GroupButtonBuyOnCredit {
    padding: 11px 32px;
    border: 0.76px solid #cdcdcd;
    border-radius: 7.56px;
    grid-area: 1 / 2 / 2 / 3;
    height: fit-content;
    align-self: center;
    width: 100%;
    text-align: center;
  }

  .GroupBuyContainer {
    display: contents;
  }
}

@media screen and (max-width: 768px) {
  .InCardSlider {
    width: 100%;
    margin: 10px auto 0;
  }

  .ProductsPatents {
    gap: 8px;
  }

  .GroupProductItem {
    border: none;
  }

  .productName {
    text-align: center;
    color: #282828;
    font-size: 11.726px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .brandImg,
  .categoryName,
  .priceMounth,
  .price,.promoPriceContainer {
    display: none;
  }

  .cardBtnSvg {
    width: 14.87px;
    height: 14.87px;
  }

  .promoPriceContainer {
    width: calc(100% - 35px);
    column-gap: 7px;
  }

  .newPrice {
    font-size: 12px;
  }

  .oldPrice {
    font-size: 8px;
  }
  .price {
    font-size: 12px;
    font-weight: bold;
  }

  .GroupSliderImages {
    height: 100px;
  }
}

@media screen and (max-width: 505px) {
  .ProductsPatents {
    grid-template-columns: minmax(70px, 30%) 14px minmax(70px, 30%) 14px minmax(
        70px,
        30%
      );
  }

  .GroupButtonBuyOnCredit {
    font-size: 12px;
    font-weight: 500;
    padding: 8px;
    justify-self: flex-end;
  }

  .GroupCreditTitle {
    font-size: 9.74px;
    font-weight: 700;
    text-align: left;
  }

  .GroupCreditPrice {
    font-size: 12.98px;
    font-weight: 700;
    text-align: left;
  }
  .oldPriceTwo{
    font-size: 17px;
  }
  .oldPriceContainerTwo .line{
    top: 10px;
  }
  .GroupPrice {
    font-size: 24.32px;
    font-weight: 900;
    text-align: left;
  }

  .GroupCreditContainer {
    min-width: 185px;
    margin: 0;
    padding: 0;
  }

  .GroupInfoBlock {
    gap: 10px;
    grid-template-columns: 1fr 100px;
  }

  .GroupButtonBuyNow {
    font-weight: 500;
    text-align: left;
    gap: 5px;
    font-size: 12px;
    padding: 8px;
    height: fit-content;
    align-self: center;
  }

  .GroupSaleParent {
    width: 36.48px;
    height: 36.48px;
  }

  .GroupPriceAndSale {
    gap: 11px;
  }

  .GroupSalePercent {
    font-size: 11.04px;
  }

  .categoryName {
    display: none;
  }

  .productName {
    font-size: 9px;
    font-weight: 400;
    height: 20px;
  }

  .priceMounth {
    margin-top: 5px;
    font-size: 6px;
  }

  .price {
    font-size: 7.38px;
  }

  .oldPrice {
    font-size: 4.69px;
  }

  .brandImg {
    max-height: 15px;
  }

  .GroupProductItem {
    padding: 6px;
  }

  .cashbackText {
    font-size: 6px;
    font-weight: bold;
  }

  .cashbackPrice {
    font-size: 6px;
    font-weight: bold;
  }

  .GroupSliderImages {
    height: 80px;
  }
}

@media only screen and (max-width: 430px) {
  .GroupInfoBlock{
    gap: 6px;
    grid-template-columns: 1fr 85px;
  }
  .GroupCreditPrice{
    font-size: 11.98px;
  }
  .GroupButtonBuyOnCredit{
    font-size: 10px;
  }
  .GroupButtonBuyNow{
    font-size: 9px;
  }
  .GroupPrice {
    font-size: 19.32px;
  }
  .oldPriceTwo{
    font-size: 14px;
  }
}